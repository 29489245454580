<template>
  <v-data-table
    :loading="loading"
    :headers="userHeaders"
    disable-filtering
    :items="users"
    disable-sort
    item-key="id"
    group-by="role"
    :sort-by="['level', 'createdAt']"
    :sort-desc="[false, false]"
    class="elevation-2 users-table"
    :footer-props="footerProps"
    :page.sync="page"
    :items-per-page.sync="itemsPerPage"
    :server-items-length="totalItems"
    @click:row="({ id }) => $router.push({ name: 'user.view', params: { id } })"
    style="cursor: pointer"
  >
    <!-- @click:row="(item) => $router.push(showUserUrl(item))" -->
    <template v-slot:top>
      <v-sheet color="lighten-3">
        <div class="d-flex flex-md-row flex-column-reverse justify-center align-center mx-5 py-2">
          <v-btn color="primary" class="ma-1" :to="{ name: 'user.create' }" v-if="canCreate">
            <v-icon>mdi-plus</v-icon>
            <div class="reload-text">New User</div>
          </v-btn>
          <v-spacer />
          <v-text-field
            v-model="query"
            solo
            hide-details
            clearable
            name="filter"
            autocomplete="filter"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
            class="search-field ma-0"
          />
        </div>
        <v-divider />
      </v-sheet>
    </template>
    <template v-slot:[`item.fullName`]="{ item }">
      {{ item.fullName || "-" }}
    </template>
    <template v-slot:[`item.locked`]="{ item }">
      <v-icon>{{ item.locked ? "mdi-check" : "mdi-minus" }}</v-icon>
    </template>
    <template v-slot:[`item.disabled`]="{ item }">
      <v-icon>{{ item.disabled ? "mdi-check" : "mdi-minus" }}</v-icon>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <template v-if="item && canView">
        <v-btn icon :to="{ name: 'user.view', params: { id: item.id } }" title="View">
          <v-icon color="green">mdi-eye</v-icon>
        </v-btn>
        <v-btn
          icon
          title="Edit"
          v-if="canUpdate"
          @click.stop="$router.push({ name: 'user.edit', params: { id: item.id } })"
        >
          <v-icon color="blue">mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon @click.stop="deleteUser(item)" title="Delete" v-if="canDelete">
          <v-icon color="red">mdi-delete</v-icon>
        </v-btn>
        <v-btn icon @click.stop="restoreUser(item)" title="Restore" v-if="canRestore">
          <v-icon color="blue">mdi-delete-restore</v-icon>
        </v-btn>
        <!-- <v-btn icon @click.stop="deleteUserConfirmation(item)" title="Delete" v-if="canDeleteForever">
          <v-icon color="red">mdi-delete-forever</v-icon>
        </v-btn> -->
      </template>
      <span v-else>-</span>
    </template>
  </v-data-table>
</template>

<script>
import * as api from "@/constants/api";
import debounce from "lodash.debounce";

export default {
  props: {
    showDeleted: {
      type: Boolean,
    },
  },
  data: () => ({
    loading: false,
    filterText: null,
    users: [],
    roles: {},
    action: null,
    totalItems: null,
    optionsLength: 6,
  }),
  computed: {
    canView() {
      return this.verifyPrivilege(api.USER_GET);
    },
    canCreate() {
      return !this.showDeleted && this.verifyPrivilege(api.USER_CREATE);
    },
    canUpdate() {
      return !this.showDeleted && this.verifyPrivilege(api.USER_UPDATE);
    },
    canDelete() {
      return !this.showDeleted && this.verifyPrivilege(api.USER_REMOVE);
    },
    canRestore() {
      return this.showDeleted && this.verifyPrivilege(api.USER_RESTORE);
    },
    userHeaders: () => [
      { text: "Id", value: "id" },
      { text: "Username", value: "username" },
      { text: "Role", value: "role" },
      { text: "Full Name", value: "fullName" },
      { text: "Disabled", value: "disabled" },
      { text: "Locked", value: "locked" },
      { text: "Actions", value: "actions" },
    ],
    footerProps: () => ({
      "items-per-page-options": [10, 15, 25, 50, 100],
    }),
    page: {
      get() {
        return Number(this.$route.query.page) || 1;
      },
      set(page) {
        this.pushCurrentRoute({ page });
      },
    },
    itemsPerPage: {
      get() {
        return Number(this.$route.query.limit) || 10;
      },
      set(limit) {
        this.pushCurrentRoute({ limit, page: 1 });
      },
    },
    query: {
      get() {
        return this.$route.query.query;
      },
      set(query) {
        this.pushCurrentRoute({ query, page: 1 });
      },
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.fetchUsers();
      },
    },
  },
  created() {
    this.pushCurrentRoute = debounce(this.pushCurrentRoute, 300, { leading: true });
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    async pushCurrentRoute({ page, limit, query }) {
      await this.$nextTick();

      const q = {};
      q.query = query === undefined ? this.query : query;
      q.page = page === undefined ? this.page : page;
      q.limit = limit === undefined ? this.itemsPerPage : limit;
      Object.keys(q).forEach((el) => {
        if (!q[el]) delete q[el];
      });

      this.$router.push({ name: this.$route.name, query: q }).catch(() => {});
    },
    async fetchUsers() {
      try {
        this.loading = true;
        const response = await this.$axios.get(
          this.showDeleted ? api.USER_LIST_DELETED : api.USER_LIST,
          {
            params: {
              page: this.page - 1,
              limit: this.itemsPerPage,
              query: this.query,
            },
          }
        );
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        this.totalItems = response.data.data.totalItems;
        this.users = response.data.data.items;
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
    async deleteUser(user) {
      try {
        const response = await this.$axios.post(`${api.USER_REMOVE}?userId=${user.id}`);
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        this.$iziToast.success({
          title: "Success",
          message: "User has been deleted",
        });
        this.fetchUsers();
      } catch (err) {
        this.$iziToast.showError(err);
      }
    },
    async restoreUser(user) {
      try {
        const response = await this.$axios.post(`${api.USER_RESTORE}?userId=${user.id}`);
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        this.$iziToast.success({
          title: "Success",
          message: "User has been restored",
        });
        this.fetchUsers();
      } catch (err) {
        this.$iziToast.showError(err);
      }
    },
    deleteUserConfirmation(user) {
      this.$iziToast.askConfirmation().then((confirm) => {
        if (confirm) {
          this.deleteUser(user);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.users-table {
  .search-field {
    width: 100%;
    max-width: 400px;
  }
  .header-tabs {
    .v-tab {
      text-transform: none;
      font-size: 1.5rem;
      letter-spacing: 0;
      font-family: "Roboto Slab", sans-serif;
      font-weight: 400;
    }
  }
  .v-data-table-header-mobile {
    display: none !important;
    height: 0 !important;
  }
}
</style>
