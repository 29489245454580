var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-2 users-table",staticStyle:{"cursor":"pointer"},attrs:{"loading":_vm.loading,"headers":_vm.userHeaders,"disable-filtering":"","items":_vm.users,"disable-sort":"","item-key":"id","group-by":"role","sort-by":['level', 'createdAt'],"sort-desc":[false, false],"footer-props":_vm.footerProps,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalItems},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"click:row":function (ref) {
	var id = ref.id;

	return _vm.$router.push({ name: 'user.view', params: { id: id } });
}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-sheet',{attrs:{"color":"lighten-3"}},[_c('div',{staticClass:"d-flex flex-md-row flex-column-reverse justify-center align-center mx-5 py-2"},[(_vm.canCreate)?_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","to":{ name: 'user.create' }}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('div',{staticClass:"reload-text"},[_vm._v("New User")])],1):_vm._e(),_c('v-spacer'),_c('v-text-field',{staticClass:"search-field ma-0",attrs:{"solo":"","hide-details":"","clearable":"","name":"filter","autocomplete":"filter","placeholder":"Search","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('v-divider')],1)]},proxy:true},{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fullName || "-")+" ")]}},{key:"item.locked",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.locked ? "mdi-check" : "mdi-minus"))])]}},{key:"item.disabled",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.disabled ? "mdi-check" : "mdi-minus"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item && _vm.canView)?[_c('v-btn',{attrs:{"icon":"","to":{ name: 'user.view', params: { id: item.id } },"title":"View"}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-eye")])],1),(_vm.canUpdate)?_c('v-btn',{attrs:{"icon":"","title":"Edit"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({ name: 'user.edit', params: { id: item.id } })}}},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.canDelete)?_c('v-btn',{attrs:{"icon":"","title":"Delete"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteUser(item)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1):_vm._e(),(_vm.canRestore)?_c('v-btn',{attrs:{"icon":"","title":"Restore"},on:{"click":function($event){$event.stopPropagation();return _vm.restoreUser(item)}}},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-delete-restore")])],1):_vm._e()]:_c('span',[_vm._v("-")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }